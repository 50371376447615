.dlt-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .dlt-content {
    flex: 1;
    padding-right: 20px;
  }
  
  .dlt-title {
    font-size: 2rem;
    color: #2175bc;
    margin-bottom: 20px;
  }
  
  .dlt-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .dlt-buttons {
    margin-top: 20px;
  }
  
  .dlt-button {
    background-color: #d32f2f;
    color: white;
    padding: 10px 15px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .dlt-image {
    flex: 1;
    text-align: center;
  }
  
  .trai-logo,
  .dlt-process-image {
    max-width: 50%;
    height: auto;
  }
  
  .dlt-process-image {
    margin-top: 20px;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .dlt-container {
      flex-direction: column;
      text-align: center;
    }
  
    .dlt-content {
      padding-right: 0;
    }
  
    .dlt-buttons {
      margin-top: 10px;
    }
  
    .dlt-button {
      margin-bottom: 10px;
    }
  }
  