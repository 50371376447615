.navigation_menu ul {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  list-style: none;
}
.css {
  border-radius: 50% !important;
  background: linear-gradient(90deg, #38b6ff 0%, #43c69b 100%) !important;
  border: 1px solid white !important;
  /* background-color: red !important; */
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: rgba(255, 255, 255, 0) !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none !important;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 600px;
}
.MuiDialog-paper {
  transition: transform 4s ease-in-out; /* Adjust the timing as needed */
}

/* When the dialog is entering */
.MuiDialog-paper-enter {
  transform: translateY(-100%);
}

/* When the dialog has entered */
.MuiDialog-paper-enter-active {
  transform: translateY(0);
}
.navigation_menu ul li {
  position: relative;
  margin: 0 20px;
  height: 10vh;
  line-height: 10vh;
  cursor: pointer;
}

.navigation_menu .drop_down li {
  margin: 0px;
  display: flex;
  padding-left: 3%;
}

.navigation_menu ul li a {
  text-decoration: none;
  color: black;
  transition: 0.3s ease-in-out;
}

/* ---------drop down -------- */
.navigation_menu .drop_down {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 2px 2px 3px #ccc, -2px -2px 3px #ccc;
  height: auto;
  min-width: 19rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  gap: 1rem;
  top: 4rem !important;
  transition: all .3s linear;
  z-index: -1;
  display: none;
}
.close-button {
  background: #ff5e5e !important;
  border: none !important;
  padding: 5px 10px !important;
  color: white !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.navigation_menu .drop_down li {
  height: 3rem;
  width: 100%;
  line-height: 3rem;
  font-weight: 800;
  text-align: center;
  transition: all 0.3s linear;
}

.navigation_menu .drop_down li a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation_menu .drop_down .dropdown_arrow {
  display: none;
}

.navigation_menu .drop_down li:hover a {
  color: #38b6ff;
}

.navigation_menu .drop_down li:hover {
  transform: translateX(10px);
}

.navigation_menu .drop_down li:hover .dropdown_arrow {
  display: block;
}

.navigation_menu li:hover > .drop_down {
  display: block;
  top: 4rem;
}

/* .navigation_menu .dropdown_box {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 2px 2px 3px #ccc, -2px -2px 3px #ccc;
  height: 30rem;
  min-width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  gap: 1rem;
  top: 10rem;
  transition: all .3s linear;
  z-index: -1;
}

.dropdown_box .ul_box .ul_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.drop_down .ul_box{
  width: 50%;
  height: 100%;
} */

.signin_btn {
  width: 80px;
  height: 30px;
  background: #000000;
  border: none;
  outline: none;
  border-radius: 30px;
}

.signin_btn a {
  text-decoration: none;
  color: #fff;
}

.header_logo {
  width: 200px;
  height: 32px;
  display: flex;
}

/* -----------mobile nevigation----------- */

.mobile_navigation {
  list-style: none;
}

.mobile_navigation li {
  position: relative;
  margin-top: 20px;
}

.mobile_navigation li a {
  text-decoration: none;
  font-size: 1.4rem;
  color: black;
}

/* ---------------media query------------- */

@media screen and (max-width: 900px) {
  .header_logo {
    width: 115px;
    height: 23px;
  }

  .navigation_menu ul li {
    margin: 0 15px;
  }

  .navigation_menu ul li a {
    font-size: 0.9rem;
    white-space: nowrap;
  }

  .signin_btn {
    /* width: 40% !important; */
    height: 25px;
  }
}
