@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
}


input[type="number"] {
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}


.buyNow_btn {
  width: 261px;
  height: 60px;
  border: 1px solid transparent;
  background: linear-gradient(to bottom right, #dce9e9, #fff, #fff) padding-box,
    linear-gradient(to bottom right, #9747FF, #9B6FEE00, #7B7FEA00, #02A9F7) border-box;
  border-radius: 60px;
  margin-top: 20px;
}

.buyNow_btn a {
  text-decoration: none;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-align: center;
  color: #0B0B0B;
}

/*------- media query for btn---------- */

@media (min-width:900px) and (max-width:1200px) {
  .buyNow_btn {
    width: 180px;
    height: 50px;
    margin-top: 18px;
  }

  .buyNow_btn a {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
  }
}

@media (min-width:600px) and (max-width:900px) {
  .buyNow_btn {
    width: 100px;
    height: 35px;
    margin-top: 12px;
  }

  .buyNow_btn a {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
  }
}

@media (max-width:600px) {
  .buyNow_btn {
    width: 100px;
    height: 28px;
    margin-top: 9px;
  }

  .buyNow_btn a {
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
  }
}