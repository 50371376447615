.rapify-dlt-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .rapify-dlt-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .rapify-dlt-subtitle {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .rapify-dlt-steps {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
  }
  
  .rapify-dlt-step {
    flex: 1;
    min-width: 250px;
    max-width: 350px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .rapify-dlt-step img {
    max-width: 50px;
    margin-bottom: 10px;
  }
  
  .rapify-dlt-step h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .rapify-dlt-step p {
    font-size: 0.9rem;
  }
  
  @media (max-width: 768px) {
    .rapify-dlt-steps {
      flex-direction: column;
      align-items: center;
    }
  
    .rapify-dlt-step {
      width: 100%;
      max-width: 500px;
    }
  }
  /* Main Container */
.ramtechno-dlt-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.ramtechno-dlt-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.ramtechno-dlt-description {
  font-size: 16px;
  margin-bottom: 30px;
}

/* Grid Container */
.ramtechno-dlt-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px;
  justify-items: center;
}

.ramtechno-dlt-operator {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 250px;
  text-align: center;
  transition: box-shadow 0.3s ease;
}

.ramtechno-dlt-operator:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.ramtechno-dlt-operator img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.ramtechno-dlt-operator span {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ramtechno-dlt-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
  }
}

@media (max-width: 480px) {
  .ramtechno-dlt-grid {
    grid-template-columns: 1fr; /* 1 column */
  }

  .ramtechno-dlt-title {
    font-size: 24px;
  }

  .ramtechno-dlt-description {
    font-size: 14px;
  }
}

  