/**
 * .card-grid-container {
 *     display: grid;
 *     grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
 *     gap: 20px;
 *     grid-gap: 50px;
 *     padding: 20px;
 *     justify-items: center;
 *     align-items: center;
 *     max-width: 1200px;
 *     margin: 0 auto;
 *   }
 *   
 *   .dgm-card {
 *     background-color: #fff;
 *     border: 1px solid black;
 *     border-radius: 10px;
 *     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 *     padding: 20px;
 *     
 *     min-height: 400px;
 *     text-align: center;
 *     transition: transform 0.3s ease;
 *   }
 *   
 *   .dgm-cardh3 {
 *     font-size: 1.2rem;
 *     color: #333;
 *   }
 *   
 *   .dgm-cardp {
 *     font-size: 1rem;
 *     color: #666;
 *     padding: 6px;
 *     margin: 10px 0;
 *     line-height: 2;
 *   }
 *   
 *   .dgm-button {
 *     background-color: #28a745;
 *     color: #fff;
 *     border: none;
 *     padding: 10px 15px;
 *     border-radius: 5px;
 *     cursor: pointer;
 *     font-size: 1rem;
 *     transition: background-color 0.3s ease;
 *     margin-top: 5%;
 *   }
 *   
 *   
 *   
 *     }
 *   }
 *
 * @format
 * @media (max-width: 768px) { .card-grid-container { grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); gap: 30px;
 */

.card-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  gap: 20px;
  grid-gap: 50px;
  padding: 20px;
  justify-items: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 0%;
}

.dgm-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border: 1px solid #000;
  border-radius: 8px;
  background-color: #fff;
  min-height: 350px;
  box-sizing: border-box;
  position: relative;
}

.dgm-cardh3 {
  margin: 10px 0;
}

.dgm-cardp {
  flex-grow: 1;
  text-align: center;
  line-height: 1.6;
}

.dgm-button {
  margin-top: auto; /* This ensures the button sticks to the bottom */
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dgm-button:hover {
  background-color: #007bff;
}

.container-dgmmainbody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  max-width: 1200px;
  margin: auto;
}

.text-content {
  max-width: 600px;
  text-align: left;
}

.text-content h1 {
  font-size: 2.9rem;
  margin-bottom: 1rem;
}

.text-content p {
  font-size: 1rem;
  color: #555;
  line-height: 2;
}

.image-content {
  /* margin-top: 2rem; */
}

.image-content img {
  max-width: 100%;
  height: 390px;
}

@media (min-width: 768px) {
  .container-dgmmainbody {
    flex-direction: row;
    justify-content: space-between;
  }

  .text-content {
    max-width: 50%;
    text-align: left;
  }

  .image-content {
    max-width: 40%;
  }
}

@media (max-width: 767px) {
  .text-content h1 {
    font-size: 2rem;
  }

  .text-content p {
    font-size: 0.9rem;
  }
}

/* agency */
.agency-mainbody {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 30px;
}
.agency-paraone {
  width: 800px;
  line-height: 1.4;
  /* padding: 20px; */
}
.agency-headingone {
  font-size: 1.5rem;
}

/* last section professional */
.experience-main-body {
  /* margin: 0 auto; */
  margin-bottom: 1%;

}
.experience-headingone {
  text-align: center;
  font-size: 25px;
  font-weight: 500;
}
.experience-select {
  text-align: center;
  margin-top: 4%;
  font-size: 20px;
  font-weight: 500;
}
/* select */
.container-dgmsearch {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  padding-left: 20px;
  max-width: 400px;
  /* padding: 10px; */
  background: white;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  position: relative;
  margin-top: 2%;
  margin-left: 20%;
}

.select-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.label-text {
  flex: 5;
  font-size: 15px;
  white-space: no-wrap;
}

.tick {
  flex: 3;
  cursor: pointer;
}
@media (max-width: 768px) {
    .container-dgmsearch {
        margin-top: 3%;
        margin-left: 0%;
    }
  .card-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 48px;
  }

  .agency-mainbody {
    flex-direction: column;
  }
  .dgm-line {
    display: none;
  }
  .agency-paraone {
    margin: 0 auto;
    font-size: 1rem;
    margin: 10px;
    width: 300px;
    line-height: 1.4;
  }
  .agency-headingone {
    font-size: 1rem;
  }
  .experience-headingone {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  .experience-select {
    text-align: center;
    margin-top: 4%;
    font-size: 13px;
    font-weight: 500;
  }
  .label-text {
    flex: 5 1;
    font-size: 11px;
  }
}
